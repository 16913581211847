import React from 'react'

import {
  BaseCard,
  Button,
  Chip,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as ConstellationIcon } from '@lattice/assets/icons/custom/constellation.svg'
import { ReactComponent as EthereumIcon } from '@lattice/assets/icons/custom/ethereum.svg'
import { useUserWalletsQueries } from '@lattice/common/queries/bundles'
import {
  AvailableNetwork,
  shortenAddress,
  useAsyncAction,
} from '@lattice/utils'
import {
  useConfirmActionProvider,
  useWalletProvider,
} from '@lattice/common/providers'
import { FetchStatus } from '@lattice/common/consts'

import styles from './view.module.scss'

export const WalletAddressesView = () => {
  const { requestConfirmation } = useConfirmActionProvider()
  const { activeWallet, requestConnectorActivation } = useWalletProvider()
  const wallets = useUserWalletsQueries()

  const isMobile = window.innerWidth < 768

  const [doRegisterWallet, { loading: loadingRegister }] = useAsyncAction(
    async (network: AvailableNetwork, primary: boolean) => {
      if (activeWallet.status !== 'connected') {
        await requestConnectorActivation()
        return
      }

      if (
        network === AvailableNetwork.CONSTELLATION &&
        activeWallet.constellation
      ) {
        const ownershipToken =
          await activeWallet.constellation.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: AvailableNetwork.CONSTELLATION,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.constellation.account,
          network: AvailableNetwork.CONSTELLATION,
          ownershipToken,
        })
        return
      }

      if (network === AvailableNetwork.ETHEREUM && activeWallet.ethereum) {
        const ownershipToken =
          await activeWallet.ethereum.requestWalletOwnershipToken()

        await wallets.walletRegisterMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: AvailableNetwork.ETHEREUM,
          primary,
        })

        await wallets.walletVerifyMut.mutateAsync({
          address: activeWallet.ethereum.account,
          network: AvailableNetwork.ETHEREUM,
          ownershipToken,
        })
        return
      }
    },
    []
  )

  const [doUnlinkWallet, { loading: loadingUnlink }] = useAsyncAction(
    async (address: string, network: AvailableNetwork) => {
      const confirmation = await requestConfirmation({
        title: 'Unlink wallet',
        description:
          'Are you sure that you want to unlink your  wallet? You will need to link a new wallet to start collecting rewards again.',
        negativeButtonContent: 'Cancel',
        positiveButtonContent: 'Yes, unlink',
      })

      if (!confirmation) {
        return
      }

      await wallets.walletUnlinkMut.mutateAsync({
        address,
        network,
      })
    },
    []
  )

  const primaryConstellationWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === AvailableNetwork.CONSTELLATION && wallet.primary
        ) ?? null
      : null

  const primaryEthereumWallet =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.find(
          (wallet) =>
            wallet.network === AvailableNetwork.ETHEREUM && wallet.primary
        ) ?? null
      : null

  const additionalEthereumWallets =
    wallets.wallets.data !== FetchStatus.NOT_FOUND
      ? wallets.wallets.data?.filter(
          (wallet) =>
            wallet.network === AvailableNetwork.ETHEREUM && !wallet.primary
        ) ?? []
      : []

  return (
    <BaseCard className={{ root: styles.card, body: styles.body }}>
      <div className={styles.title}>
        <h2>Wallet Addresses</h2>
        <span>
          Link your wallet address from different networks to start getting
          rewards.
        </span>
      </div>
      <div className={styles.items}>
        {(wallets.wallets.isLoading || loadingUnlink) && (
          <PendingContent variants={['min-height']} />
        )}
        {!wallets.wallets.isLoading && (
          <>
            <div className={styles.link}>
              <h4>Constellation</h4>
              <h5>Primary address</h5>
              {primaryConstellationWallet === null && (
                <Button
                  variants={['primary-outlined', 'full-width']}
                  onClick={() =>
                    doRegisterWallet(AvailableNetwork.CONSTELLATION, true)
                  }
                  loading={loadingRegister}
                >
                  <ConstellationIcon />
                  Link Wallet
                </Button>
              )}
              {primaryConstellationWallet !== null && (
                <InputRow
                  variants={['full-width']}
                  readOnly
                  value={
                    isMobile
                      ? shortenAddress(primaryConstellationWallet.address, 6, 6)
                      : primaryConstellationWallet.address
                  }
                  icon={<Chip variants={['mini']}>Unlink</Chip>}
                  onIconClick={() =>
                    doUnlinkWallet(
                      primaryConstellationWallet.address,
                      primaryConstellationWallet.network
                    )
                  }
                />
              )}
            </div>
            <div className={styles.link}>
              <h4>Ethereum / EVM</h4>
              <h5>Primary address</h5>
              {primaryEthereumWallet === null && (
                <Button
                  variants={['primary-outlined', 'full-width']}
                  onClick={() =>
                    doRegisterWallet(AvailableNetwork.ETHEREUM, true)
                  }
                  loading={loadingRegister}
                >
                  <EthereumIcon />
                  Link Wallet
                </Button>
              )}
              {primaryEthereumWallet !== null && (
                <InputRow
                  variants={['full-width']}
                  readOnly
                  value={
                    isMobile
                      ? shortenAddress(primaryEthereumWallet.address, 6, 6)
                      : primaryEthereumWallet.address
                  }
                  icon={<Chip variants={['mini']}>Unlink</Chip>}
                  onIconClick={() =>
                    doUnlinkWallet(
                      primaryEthereumWallet.address,
                      primaryEthereumWallet.network
                    )
                  }
                />
              )}
              {(primaryEthereumWallet !== null ||
                additionalEthereumWallets.length > 0) && (
                <>
                  <h5>Additional addresses</h5>
                  {additionalEthereumWallets.map((wallet) => (
                    <InputRow
                      key={wallet.id}
                      variants={['full-width']}
                      readOnly
                      value={
                        isMobile
                          ? shortenAddress(wallet.address, 6, 6)
                          : wallet.address
                      }
                      icon={<Chip variants={['mini']}>Unlink</Chip>}
                      onIconClick={() =>
                        doUnlinkWallet(wallet.address, wallet.network)
                      }
                    />
                  ))}
                  {primaryEthereumWallet !== null && (
                    <Button
                      variants={['primary-outlined', 'full-width']}
                      onClick={() =>
                        doRegisterWallet(AvailableNetwork.ETHEREUM, false)
                      }
                      loading={loadingRegister}
                    >
                      <EthereumIcon />
                      Add Ethereum Wallet
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </BaseCard>
  )
}
