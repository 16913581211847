import semver from 'semver'
import { TFunction } from 'i18next'
import { CreateConnectorFn } from 'wagmi'
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors'
import { stargazerWalletWagmiConnector } from '@stardust-collective/web3-react-stargazer-connector'

import { ExecutionContext } from '../execution_context'

type IConnectorInfo = {
  connector: CreateConnectorFn
  connectorId: string
  description: string
  href: string | null
  logoUrl: string | null
  mobile?: boolean
  mobileOnly?: true
  name: string | ((t: TFunction) => string)
  primary?: true
  getSubHeader?: () => string | null // Dynamically generate subheader if needed
  allowedToConnect?: () => boolean // Dynamically decide if wallet can connect
  showLink?: () => boolean // Dyanically decide to have opt link or trigger ext option
}

const AvailableConnectors: Record<string, IConnectorInfo> = {
  stargazer: {
    connector: stargazerWalletWagmiConnector({}),
    connectorId: 'stargazerWalletEIP1193',
    name: 'Stargazer',
    logoUrl:
      'https://cloudflare-ipfs.com/ipfs/bafkreiapdizo36f3yeg7g6l46f7ahbbkyo4otufnfyqri6louysr3grpzy',
    description: 'Multi-chain wallet for DAG and ETH.',
    href: 'https://chrome.google.com/webstore/detail/stargazer-wallet/pgiaagfkgcbnmiiolekcfmljdagdhlcm',
    mobile: false,
    getSubHeader: () => {
      if (!window.stargazer) {
        return 'Stargazer is not installed'
      }

      if (!('getProvider' in window.stargazer)) {
        return 'Update to v3.4.0 or later'
      }

      if (typeof (window?.stargazer).version !== 'string') {
        return 'Update to v3.4.0 or later'
      }

      if (
        !semver.satisfies(
          window.stargazer.version,
          ExecutionContext.nodeEnv === 'production' ? '>3.5.0' : '>=3.5.0'
        )
      ) {
        return 'Update to v3.5.0 or later'
      }

      return null
    },
    allowedToConnect: () => {
      if (!window.stargazer) {
        return false
      }

      if (!('getProvider' in window.stargazer)) {
        return false
      }

      if (typeof (window?.stargazer).version !== 'string') {
        return false
      }

      if (
        !semver.satisfies(
          window.stargazer.version,
          ExecutionContext.nodeEnv === 'production' ? '>3.5.0' : '>=3.5.0'
        )
      ) {
        return false
      }

      return true
    },
    showLink: () => {
      // Not installed
      return !window.hasOwnProperty('stargazer')
    },
  },
  metamask: {
    connector: injected(),
    connectorId: 'injected',
    name: 'MetaMask',
    logoUrl:
      'https://ipfs.io/ipfs/QmTE7VPXMhriKAobMWEiC5S3oG22p4G6AXGyGdNWQTQ3Fv',
    description: 'Easy-to-use browser extension.',
    href: 'https://metamask.io/',
    mobile: false,
    showLink: () => {
      return (
        !window.hasOwnProperty('ethereum') ||
        !(window as any).ethereum.isMetaMask
      )
    },
  },
  walletconnect: {
    connector: walletConnect({
      projectId: ExecutionContext.WalletConnectProjectId,
    }),
    connectorId: 'walletConnect',
    name: 'WalletConnect',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    logoUrl:
      'https://ipfs.io/ipfs/QmZRVqHpgRemw13aoovP2EaQdVtjzXRaQGQZsCLXWaNn9x',
    href: null,
    mobile: true,
  },
  coinbase: {
    connector: coinbaseWallet({
      appName: 'Lattice',
      appLogoUrl:
        'https://lattice-exchange-assets.s3.amazonaws.com/round-logo.png',
    }),
    connectorId: 'coinbaseWalletSDK',
    name: 'Coinbase Wallet',
    logoUrl:
      'https://ipfs.io/ipfs/QmbJKEaeMz6qR3DmJSTxtYtrZeQPptVfnnYK72QBsvAw5q',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    mobile: true,
  },
}

export { AvailableConnectors, IConnectorInfo }
