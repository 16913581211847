import React, { useEffect } from 'react'

import {
  BaseCard,
  Button,
  Chip,
  InputRow,
  PendingContent,
} from '@lattice/common/components'
import { ReactComponent as TwitterXIcon } from '@lattice/assets/icons/custom/twitter_x.svg'
import {
  useConfirmActionProvider,
  useUserOAuthProvider,
  useUserProvider,
} from '@lattice/common/providers'
import { FetchStatus, OAuthProviderURI } from '@lattice/common/consts'

import styles from './view.module.scss'

export const SocialAccountsView = () => {
  const { user } = useUserProvider()
  const { requestConfirmation } = useConfirmActionProvider()

  const {
    operations,
    userOAuthProviderStatuses,
    doRequestOAuthFlowForProvider,
    requestUserOAuthStatusForProvider,
    unregisterUserOAuthToken,
  } = useUserOAuthProvider()

  const doUnlinkAccount = async () => {
    const confirmation = await requestConfirmation({
      title: 'Unlink account',
      description:
        'Are you sure that you want to unlink your X account? You will need to link a new account to start collecting rewards again.',
      negativeButtonContent: 'Cancel',
      positiveButtonContent: 'Yes, unlink',
    })

    if (!confirmation) {
      return
    }

    await unregisterUserOAuthToken(OAuthProviderURI.TWITTER_X)
    requestUserOAuthStatusForProvider(OAuthProviderURI.TWITTER_X)
  }

  useEffect(() => {
    requestUserOAuthStatusForProvider(OAuthProviderURI.TWITTER_X)
  }, [user])

  const providerStatus =
    userOAuthProviderStatuses.resource[OAuthProviderURI.TWITTER_X]

  return (
    <BaseCard className={{ root: styles.card, body: styles.body }}>
      <div className={styles.title}>
        <h2>Social Accounts</h2>
        <span>Link your social accounts to start getting rewards.</span>
      </div>
      <div className={styles.items}>
        <div className={styles.link}>
          <h4>Twitter / X</h4>
          {[
            operations.checkAndFinishOAuthFlow.status === FetchStatus.PENDING,
            operations.unregisterUserOAuthToken.status === FetchStatus.PENDING,
            operations.doRequestOAuthFlowForProvider.status !==
              FetchStatus.PENDING &&
              userOAuthProviderStatuses.status === FetchStatus.PENDING,
          ].some((c) => c) && <PendingContent variants={['min-height']} />}

          {operations.checkAndFinishOAuthFlow.status !== FetchStatus.PENDING &&
            operations.unregisterUserOAuthToken.status !==
              FetchStatus.PENDING && (
              <>
                {providerStatus?.status === 'not_registered' && (
                  <Button
                    variants={['primary-outlined', 'full-width']}
                    onClick={() =>
                      doRequestOAuthFlowForProvider(OAuthProviderURI.TWITTER_X)
                    }
                    loading={
                      operations.doRequestOAuthFlowForProvider.status ===
                      FetchStatus.PENDING
                    }
                  >
                    <TwitterXIcon />
                    Connect account
                  </Button>
                )}
                {providerStatus?.status === 'registered' && (
                  <InputRow
                    variants={['full-width']}
                    readOnly
                    value={'@' + providerStatus.publicRecord.username}
                    icon={<Chip variants={['mini']}>Unlink</Chip>}
                    onIconClick={doUnlinkAccount}
                  />
                )}
              </>
            )}
        </div>
      </div>
    </BaseCard>
  )
}
