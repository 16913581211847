import { TFunction } from 'i18next'
import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { ReactComponent as DashboardIcon } from '@lattice/assets/images/svgs/dashboardIcon.svg'
import { ReactComponent as GovernanceIcon } from '@lattice/assets/images/svgs/governanceIcon.svg'
import { ReactComponent as LaunchpadIcon } from '@lattice/assets/images/svgs/launchpadIcon.svg'
import { ReactComponent as NodeManagerIcon } from '@lattice/assets/images/svgs/nodeManagerIcon.svg'
import { ReactComponent as RewardsIcon } from '@lattice/assets/images/svgs/rewardsIcon.svg'
import { ReactComponent as SwapIcon } from '@lattice/assets/images/svgs/swapIcon.svg'
import { ReactComponent as Lock } from '@lattice/assets/images/svgs/closedLock.svg'

import { DashboardView } from './DashboardView'
import { LaunchpadView } from './LaunchpadView'
import { RewardsView } from './RewardsView'
import { NodeManagerView } from './NodeManagerView'
import { SwapBuyView } from './SwapBuyView'
import { UserView } from './UserView'
import { GovernanceView } from './GovernanceView'
import { TermsOfService } from './TermsOfServiceView'
import { PrivacyPolicy } from './PrivacyPolicyView'
import { NotFoundView } from './NotFoundView'
import * as LaunchpadViews from './LaunchpadView/views'
import * as RewardsViews from './RewardsView/views'
import * as DtmViews from './RewardsView/views/DtmNodesDetailsView/views'
import * as BountyViews from './RewardsView/views/BountyDetailsView/views'
import * as NodeManagerViews from './NodeManagerView/views'
import * as SwapBuyViews from './SwapBuyView/views'
import * as UserViews from './UserView/views'
import * as GovernanceViews from './GovernanceView/views'
import * as UserMyAccountViews from './UserView/views/MyAccountView/views'
import { StakingPoolsView } from './StakingPoolsView'

type RouteConfig = {
  id: string
  path: string
  name?: (t: TFunction) => string
  isProtected?: boolean
  icon?: JSX.Element
  hidden?: boolean
} & RouteObject

const routes: RouteConfig[] = [
  {
    id: 'dashboard',
    path: '/dashboard',
    name: (t) => t('containers.routes.dashboard', 'Dashboard'),
    element: <DashboardView />,
    icon: <DashboardIcon />,
  },
  {
    id: 'rewards',
    path: '/rewards',
    name: (t) => t('containers.routes.rewardsPrograms', 'Rewards Programs'),
    element: <RewardsView />,
    icon: <RewardsIcon />,
    children: [
      { index: true, element: <RewardsViews.ProgramsView /> },
      {
        path: '/rewards/bounties/dtm-nft',
        element: <RewardsViews.DtmNodesDetailsView />,
        children: [
          {
            index: true,
            element: <DtmViews.GeneralDetailsView />,
          },
          {
            path: 'redeem',
            element: <DtmViews.RedeemNftView />,
          },
        ],
      },
      {
        path: '/rewards/bounties/dtm-baseline',
        element: <Navigate to="../bounties/dtm-nft" />,
      },
      {
        path: '/rewards/bounties/:slug',
        element: <RewardsViews.BountyDetailsView />,
        children: [
          {
            index: true,
            element: <BountyViews.GeneralDetailsView />,
          },
        ],
      },
      {
        path: '/rewards/validators/testnet',
        element: <RewardsViews.TestnetValidatorProgramDetailView />,
      },
      {
        path: '/rewards/integrations-net/operators',
        element: <RewardsViews.NodeOperatorProgramDetailView />,
      },
      {
        path: '/rewards/hgtp-metagraphs/dor/operators',
        element: <RewardsViews.HgtpMetagraphOperatorProgramDetailView />,
      },
      {
        path: '/rewards/staking/:slug',
        element: <RewardsViews.StakingProgramDetailView />,
      },
      {
        path: '/rewards/soft-nodes/:slug',
        element: <RewardsViews.SoftNodeProgramDetailView />,
      },
    ],
  },
  {
    id: 'launchpad',
    path: '/launchpad',
    name: (t) => t('containers.routes.launchpad', 'Launchpad'),
    element: <LaunchpadView />,
    icon: <LaunchpadIcon />,
    children: [
      { index: true, element: <LaunchpadViews.ProjectsView /> },
      { path: ':projectSlug', element: <LaunchpadViews.ProjectDetailsView /> },
    ],
  },
  {
    id: 'swap',
    path: '/swap-buy',
    name: (t) => t('containers.routes.swap-crypto', 'Swap / Buy'),
    element: <SwapBuyView />,
    icon: <SwapIcon />,
    children: [
      { element: <Navigate to="swap" />, index: true },
      {
        path: 'swap',
        element: <SwapBuyViews.SwapGeneralView />,
        children: [
          { element: <Navigate to="method" />, index: true },
          { path: 'method', element: <SwapBuyViews.SwapSelectMethodView /> },
          { path: 'exolix', element: <SwapBuyViews.SwapExolixView /> },
          { path: 'lifi', element: <SwapBuyViews.SwapLifiView /> },
        ],
      },
      {
        path: 'buy',
        element: <SwapBuyViews.BuyGeneralView />,
        children: [
          { element: <Navigate to="method" />, index: true },
          { path: 'method', element: <SwapBuyViews.BuySelectMethodView /> },
          { path: 'simplex', element: <SwapBuyViews.BuySimplexView /> },
          { path: 'topper', element: <SwapBuyViews.BuyTopperView /> },
        ],
      },
    ],
  },
  {
    id: 'pools',
    path: '/pools',
    name: (t) => t('containers.routes.latticePools', 'Lattice Pools'),
    element: <StakingPoolsView />,
    icon: <Lock />,
    children: [
      {
        path: '*',
        element: <StakingPoolsView />,
      },
    ],
  },
  {
    id: 'nodes',
    path: '/nodes',
    name: (t) => t('containers.routes.nodes', 'Nodes'),
    element: <NodeManagerView />,
    icon: <NodeManagerIcon />,
    children: [
      {
        index: true,
        element: <NodeManagerViews.NodesDashboardView />,
      },
      {
        path: 'dtm-nodes/:slug',
        element: <NodeManagerViews.DtmNodeDetailsView />,
      },
      {
        path: 'soft-node',
        element: <NodeManagerViews.NodeDetailsLayoutView />,
        children: [
          { path: ':softNode', element: <NodeManagerViews.NodeDetailsView /> },
        ],
      },
    ],
  },
  {
    id: 'governance',
    path: '/governance',
    name: (t) => t('containers.routes.governance', 'Governance'),
    element: <GovernanceView />,
    icon: <GovernanceIcon />,
    children: [
      { element: <Navigate to="stats" />, index: true },
      { path: 'stats', element: <GovernanceViews.StatsView /> },
      { path: 'lock', element: <GovernanceViews.LockView /> },
      { path: 'claim', element: <GovernanceViews.ClaimView /> },
    ],
  },
  {
    id: 'user',
    path: '/user',
    element: <UserView />,
    children: [
      { element: <Navigate to="signin" />, index: true },
      { path: 'signin', element: <UserViews.SignInView /> },
      { path: 'signup', element: <UserViews.SignUpView /> },
      { path: 'verifyemail', element: <UserViews.VerifyEmailView /> },
      { path: 'verifyemailcode', element: <UserViews.VerifyEmailCodeView /> },
      { path: 'resetpassword', element: <UserViews.ResetPasswordView /> },
      { path: 'kyc', element: <UserViews.KycView /> },

      { path: 'rewards', element: <UserViews.RewardsHistoryView /> },
      {
        path: 'resetpasswordconfirm',
        element: <UserViews.ResetPasswordConfirmView />,
      },
      {
        path: 'myaccount',
        element: <UserViews.MyAccountView />,
        children: [
          { element: <Navigate to="profile" />, index: true },
          {
            path: 'profile',
            element: <UserMyAccountViews.ProfileSettingsView />,
          },
          {
            path: 'wallets',
            element: <UserMyAccountViews.WalletAddressesView />,
          },
          {
            path: 'socialaccounts',
            element: <UserMyAccountViews.SocialAccountsView />,
          },
          {
            path: 'security',
            element: <UserMyAccountViews.SecurityView />,
          },
        ],
      },
    ],
    hidden: true,
  },
  {
    id: 'terms-of-service',
    path: '/terms-of-service',
    name: (t) => t('containers.routes.termsOfService', 'Terms of Service'),
    element: <TermsOfService />,
    hidden: true,
  },
  {
    id: 'privacy',
    path: '/privacy',
    name: (t) => t('containers.routes.privacy', 'Privacy Policy'),
    element: <PrivacyPolicy />,
    hidden: true,
  },
  {
    id: 'NotFound',
    path: '*',
    name: (t) => t('containers.routes.NotFound', 'Not Found'),
    element: <NotFoundView />,
    hidden: true,
  },
]

export { routes, RouteConfig }
